<div class="p-3">
    <div class="modal-header">
        <div>
            <h4 class="modal-title">MAKE CAR REQUEST</h4>
            <span class="car-request-span">FILL THE FORM BELOW TO REQUEST</span>
        </div>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div ngbDropdown class="col-12 col-lg-3 col-md-6 col-sm-6 d-inline-block">
                <select class="form-select select1" aria-label="Default select example">
                    <option selected>Land Rover</option>
                    <option value="1">Make car</option>
                    <option value="2">Bently</option>
                    <option value="3">Jaguar</option>
                </select>
            </div>
            <div ngbDropdown class="col-12 col-lg-3 col-md-6 col-sm-6 d-inline-block">
                <select class="form-select select2" aria-label="Default select example">
                    <option selected>Car Model</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div ngbDropdown class="col-12 col-lg-3 col-md-6 col-sm-6 d-inline-block">
                <select class="form-select select3" aria-label="Default select example">
                    <option selected>Car Tier</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div ngbDropdown class="col-12 col-lg-3 col-md-6 col-sm-6 d-inline-block">
                <select class="form-select select4" aria-label="Default select example">
                    <option selected>Car Color</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div ngbDropdown class="col-12 col-lg-3 col-md-6 col-sm-6 d-inline-block">
                <select class="form-select select5" aria-label="Default select example">
                    <option selected>Car Condition</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-12 col-lg-3 col-md-6 col-sm-6 ">
                <select class="form-select select6" aria-label="Default select example">
                    <option selected>Buying Time</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div ngbDropdown class="col-12 col-lg-6 d-inline-block">
                <label for="customRange1" class="form-label">Price Range</label>
                <input type="range" class="form-range" min="1" max="10000" [(ngModel)]="sliderValue" id="customRange1">
                <span>$ <span>{{ sliderValue }}</span></span>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center p-4">
        <!-- <button type="button" class="btn" (click)="openNewModal()">MAKE NEW REQUEST</button> -->

        <app-button label="MAKE NEW REQUEST&nbsp;&nbsp;" bgColor="#4AB3F4" width="max-content
        " height="40px" [iconImg]="'/assets/images/seller-dashboard/arrow-16.png'"
            (click)="openNewModal()"></app-button>
    </div>

</div>