import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss'
})
export class DatePickerComponent {
  @Input() dateLabel?: string;

  selectedDate: string = '';

  openDatePicker(dateInput: HTMLInputElement) {
    dateInput.showPicker();
  }

  updateSelectedDate(date: string) {
    this.selectedDate = date;
  }
}
