<div class="date-picker">
    <span>{{dateLabel}}</span>
    <input #dateInput type="date" class="transparent-input" (change)="updateSelectedDate(dateInput.value)" />

    <img src="/assets/images/seller-dashboard/blue-calendar.png" alt="calendar icon" class="calendar-icon"
        (click)="openDatePicker(dateInput)" />
</div>

<div class="selected-date">
    <!-- {{ selectedDate ? 'Selected Date: ' + selectedDate : 'No date selected' }} -->
    {{selectedDate}}
</div>