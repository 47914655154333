import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrl: "./file-upload.component.scss",
})
export class FileUploadComponent {
  @Input() label: string = "1. UPLOAD CAR CONTRACT"; // Default label
  @Input() uniqueId: string = "file-upload";
  // @Output() fileUploaded = new EventEmitter<File>();
  @Output() fileStatusChanged = new EventEmitter<string | null>();

  fileName: string = "No File Selected";
  fileSize: string = "";
  progress: number = 0;
  isUploadComplete: boolean = false;
  uploadInterval: any;

  // triggerFileUpload(): void {
  //   const fileInput = document.getElementById(
  //     this.uniqueId
  //   ) as HTMLInputElement;
  //   if (fileInput) {
  //     fileInput.click();
  //   }
  // }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.fileSize = `${(file.size / 1024 / 1024).toFixed(2)} MB`;
      this.fileStatusChanged.emit(this.fileName);
      this.uploadFile(file);
    }
  }

  uploadFile(file: File): void {
    this.progress = 0;
    this.isUploadComplete = false;

    this.uploadInterval = setInterval(() => {
      this.progress += 10;
      if (this.progress >= 100) {
        clearInterval(this.uploadInterval);
        this.isUploadComplete = true;
        this.fileStatusChanged.emit(this.fileName);
      }
    }, 200);
  }

  cancelUpload(): void {
    this.progress = 0;
    clearInterval(this.uploadInterval);
    this.isUploadComplete = false;
    this.fileName = "No File Selected";
    this.fileSize = "";
    this.fileStatusChanged.emit(null);
    const fileInput = document.getElementById(
      this.uniqueId
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  }
}
