<!-- <div class="car-gallary-container">
    <div class="car-img-section">
        <img src="/assets/images/seller-dashboard/chevrolet-car.png">
    </div>


    <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="false">
        <ng-template ngbSlide>
            <div class="row">
                <div class="col-4">
                    <div class="card">
                        <img src="/assets/images/seller-dashboard/car1.jfif" class="card-img-top" alt="Image 1">

                    </div>
                </div>
                <div class="col-4">
                    <div class="card">
                        <img src="/assets/images/seller-dashboard/car3.jfif" class="card-img-top" alt="Image 2">

                    </div>
                </div>
                <div class="col-4">
                    <div class="card">
                        <img src="/assets/images/seller-dashboard/car1.jfif" class="card-img-top" alt="Image 3">

                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template ngbSlide>
            <div class="row">
                <div class="col-4">
                    <div class="card">
                        <img src="/assets/images/seller-dashboard/car1.jfif" class="card-img-top" alt="Image 4">

                    </div>
                </div>
                <div class="col-4">
                    <div class="card">
                        <img src="/assets/images/seller-dashboard/car3.jfif" class="card-img-top" alt="Image 5">

                    </div>
                </div>
                <div class="col-4">
                    <div class="card">
                        <img src="/assets/images/seller-dashboard/car1.jfif" class="card-img-top" alt="Image 6">

                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-carousel>

</div> -->

<div class="car-gallary-container">
  <div class="car-img-section">
    <img [src]="currentImage" alt="Main Car Image" />
  </div>

  <ngb-carousel
    [showNavigationArrows]="true"
    [showNavigationIndicators]="false"
  >
    <ng-template ngbSlide *ngFor="let slideImages of carImages | slider : 3">
      <div class="row">
        <div class="col-4" *ngFor="let image of slideImages">
          <div class="card" (click)="onImageClick(image)">
            <img [src]="image" class="card-img-top" alt="Car Image" />
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
