<div
  class="reset-password-modal justify-item-center p-4 bg-white rounded shadow-lg position-relative"
>
  <!-- Modal Content -->
  <div>
    <!-- Modal Header -->
    <div
      class="modal-header text-black d-flex justify-content-between align-center"
    >
      <div class="warning-text">DO YOU REALLY WANT TO RESET PASSWORD?</div>
      <button
        type="button"
        class="btn-close rounded-5"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      ></button>
    </div>

    <!-- Modal Body -->
    <div class="modal-body d-flex justify-content-start align-items-center">
      <div class="me-3 fw-bold">Email:</div>
      <input
        type="form-control"
        disabled
        [(ngModel)]="email"
        class="email-input-label"
      />
    </div>

    <!-- Modal Footer -->
    <div class="modal-footer d-flex justify-content-center">
      <!-- <button class="btn btn-danger text-white" (click)="onCancel()"> -->
      <button class="reset-noo-btn text-white" label="" (click)="onCancel()">
        NO
      </button>
      <button class="reset-confirm-btn text-white" (click)="onConfirm()">
        CONFIRM
      </button>
    </div>
  </div>
</div>
