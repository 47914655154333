import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-generate-url-popup',
  templateUrl: './generate-url-popup.component.html',
  styleUrl: './generate-url-popup.component.scss'
})
export class GenerateUrlPopupComponent {
  activeModal = inject(NgbActiveModal);
}
