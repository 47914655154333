import { Component } from "@angular/core";

@Component({
  selector: "app-upload-car-photos",
  templateUrl: "./upload-car-photos.component.html",
  styleUrl: "./upload-car-photos.component.scss",
})
export class UploadCarPhotosComponent {
  uploadedImages: string[] = [];

  triggerFileInput(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const files = Array.from(input.files);

      files.forEach((file) => {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.uploadedImages.unshift(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
  }

  removeImage(index: number): void {
    this.uploadedImages.splice(index, 1);
  }
}
