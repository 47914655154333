<div class="chat-user-list">
  <div
    class="chat-user d-flex align-item-center"
    *ngFor="let user of users"
    (click)="onUserSelect(user)"
  >
    <div class="user-avatar">
      <img [src]="user.avatar" />
    </div>
    <div>
      <div class="user-name">
        {{ user.name }}
        <span class="user-status">
          <span class="status-dot"></span>
        </span>
      </div>
      <div class="user-message">
        {{ user.message }}
      </div>
      <div class="message-time">{{ user.time }}</div>
    </div>
  </div>
</div>
