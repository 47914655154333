<div class="row latest-card">
    <div class="col-12 col-md-12 col-lg-12 col-xl-10 latest-sale">
        <div class="d-block d-xl-flex">
            <div>Latest Bid</div>
            <div>{{latestSalePrice}}</div>
        </div>
        <div class="latest-card-content">Toyota Camry 2019 listed by<b>Jessica Albertio</b> and promoted by <b>Henry Jifferson</b></div>
    </div>

    <div class="col-12 col-md-12 col-lg-12 col-xl-2 latest-sale-btn">
        <app-button [iconImg]="'assets/images/seller-dashboard/arrow-16.png'"
            [width]="screenWidth <= 1199 ? '100%' : '40px'" [height]="screenWidth <= 1199 ? '40px' : '128px'"
            [margin]="screenWidth <= 1199 ? '0' : '0 0 0 -8px'" bgColor="#4AB3F4" padding="0">
        </app-button>
    </div>
</div>
