import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-chat-userlist",
  templateUrl: "./chat-userlist.component.html",
  styleUrl: "./chat-userlist.component.scss",
})
export class ChatUserlistComponent {
  @Input() users: any[] = []; // List of users
  @Output() selectUser = new EventEmitter<any>(); // Emit selected user

  onUserSelect(user: any) {
    this.selectUser.emit(user); // Emit the selected user
  }
}
