<div class="modal-header justify-content-center">
    <img src="../../../../assets/images/seller-dashboard/RequestPlaced.png" width="75%" height="auto">


</div>
<div class="modal-body">
    <h4 class="">REQUEST PLACED</h4>
    <span>
        Congratulations, the request has been placed successfully and we will get back to you with confirmation on
        further time! Get yourself an awesome day.
    </span>
</div>
<div class="modal-footer d-block d-lg-flex justify-content-center">
    <!-- <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button> -->
    <app-button label="MAKE NEW REQUEST&nbsp;&nbsp;" bgColor="#4AB3F4" width="90%" margin=" 0 20px" height="40px"
        [iconImg]="'/assets/images/seller-dashboard/arrow-16.png'"
        (click)="activeModal.dismiss('Cross click')"></app-button>
    <app-button label="THANKS !" bgColor="#565656" [width]="screenWidth <= 567 ? '70%' : '90%'" margin="0 20px"
        height="40px" (click)="closeModal()"></app-button>
</div>