import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrl: './select-dropdown.component.scss'
})
export class SelectDropdownComponent {
  @Input() label!: string;
  @Input() options: string[] = [];
  // @Input() selectedValue: string = '';
  @Input() selectedValue!: string; 
  @Output() valueChange = new EventEmitter<string>();

  onSelectChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
    this.valueChange.emit(value);
  }
}
