import { Component, inject, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-reset-password-popup",
  templateUrl: "./reset-password-popup.component.html",
  styleUrl: "./reset-password-popup.component.scss",
})
export class ResetPasswordPopupComponent implements OnInit {
  activeModal = inject(NgbActiveModal);
  closeModal: any;
  email: any;
  public elem: any;

  ngOnInit() {
    this.elem = document.documentElement;
    const user = JSON.parse(localStorage.getItem("userData"));
    // console.log("from header", user);
    this.email = user.email?.trim();
    // console.log("from header", user.username);
  }
  constructor(private modalService: NgbModal, private toastr: ToastrService) {}
  // onClose(): void {
  //   this.closeModal.emit(); // Emit close event to parent
  // }

  onCancel(): void {
    this.modalService.dismissAll(); // Close modal without any action
  }
  onConfirm(): void {
    if (this.email.trim()) {
      // Show Toastr success message
      this.toastr.success("Reset password link sent in email", "Success");
      this.modalService.dismissAll();
    }
  }
}
