<h5 class="container car-upload-label text-dark align-items-center">
  UPLOAD CAR PHOTOS
</h5>
<div class="container upload-car-photos mt-2 g-1">
  <div class="row g-2">
    <!-- Upload Box -->
    <div
      class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center"
    >
      <div
        class="upload-box text-center p-1"
        (click)="triggerFileInput(fileInput)"
        style="cursor: pointer; align-content: center"
      >
        <div class="upload-image mx-auto"></div>
        <p class="font-weight-bold lh-1">UPLOAD IMAGE</p>
        <p class="lh-1 drag-text">
          Drag your file or
          <label style="cursor: pointer"><u> Browse </u></label>
          on your device
          <input
            type="file"
            #fileInput
            (change)="onFileSelected($event)"
            class="d-none"
            multiple
          />
        </p>
      </div>
    </div>

    <!-- Image Grid -->
    <div
      *ngFor="let image of uploadedImages; let i = index"
      class="upload-images col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 justify-item-center d-flex justify-content-center"
    >
      <div class="image-container justify-item-center">
        <div
          class="position-relative d-flex justify-content-center align-items-center"
        >
          <img
            [src]="image"
            class="img-fluid rounded shadow-sm"
            alt="Uploaded Image"
          />
        </div>
        <i class="fa-solid fa-xmark remove-image" (click)="removeImage(i)"></i>
      </div>
    </div>
  </div>
</div>
