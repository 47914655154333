import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// common components
import { CarDataComponent } from "./car-data/car-data.component";
// import { TabsComponent } from './tabs/tabs.component';
import { TimerComponent } from "./timer/timer.component";
import { DashboardLeftHeaderComponent } from "./dashboard-left-header/dashboard-left-header.component";
import { CarDetailsComponent } from "./car-details/car-details.component";
import { CarCardDataComponent } from "./car-card-data/car-card-data.component";
import { ButtonComponent } from "./button/button.component";
import { CarCardGridLayoutComponent } from "./car-card-grid-layout/car-card-grid-layout.component";
import { BalanceAndStatsCardComponent } from "./balance-and-stats-card/balance-and-stats-card.component";
import { LatestSaleCardComponent } from "./latest-sale-card/latest-sale-card.component";
import { LatestBidCardComponent } from "./latest-bid-card/latest-bid-card.component";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { UploadCarPhotosComponent } from "./upload-car-photos/upload-car-photos.component";
import { SelectDropdownComponent } from "./select-dropdown/select-dropdown.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { FormsModule } from "@angular/forms";
import { CarGallaryComponent } from "./car-gallary/car-gallary.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ProductTechnicalSpecsComponent } from "./product-technical-specs/product-technical-specs.component";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { SliderPipe } from "src/app/components/pipes/slider.pipe";
import { CommonInputComponent } from "./common-input/common-input.component";
import { ChatUserlistComponent } from "./chat-userlist/chat-userlist.component";
import { ReactiveFormsModule } from "@angular/forms";
@NgModule({
  declarations: [
    CarDataComponent,
    // TabsComponent,
    TimerComponent,
    DashboardLeftHeaderComponent,
    CarDetailsComponent,
    CarCardDataComponent,
    ButtonComponent,
    CarCardGridLayoutComponent,
    BalanceAndStatsCardComponent,
    LatestSaleCardComponent,
    LatestBidCardComponent,
    DatePickerComponent,
    UploadCarPhotosComponent,
    SelectDropdownComponent,
    FileUploadComponent,
    CarGallaryComponent,
    ProductTechnicalSpecsComponent,
    SliderPipe,
    CommonInputComponent,
    ChatUserlistComponent,
  ],

  imports: [
    CommonModule,
    NgbModule,
    NgbCarouselModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CarDataComponent,
    // TabsComponent,
    TimerComponent,
    DashboardLeftHeaderComponent,
    CarDetailsComponent,
    CarCardDataComponent,
    ButtonComponent,
    CarCardGridLayoutComponent,
    BalanceAndStatsCardComponent,
    LatestSaleCardComponent,
    LatestBidCardComponent,
    DatePickerComponent,

    UploadCarPhotosComponent,
    SelectDropdownComponent,
    FileUploadComponent,

    CarGallaryComponent,
    ProductTechnicalSpecsComponent,
    CommonInputComponent,

    ChatUserlistComponent,
  ],
})
export class CommonComponentsModule {}
