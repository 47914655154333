import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ResetPasswordPopupComponent } from "src/app/components/models/reset-password-popup/reset-password-popup.component";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  @Input() userFullName?: string;
  // modalService: any;

  constructor(public router: Router, private modalService: NgbModal) {
    if (JSON.parse(localStorage.getItem("user"))) {
    } else {
    }
  }

  ngOnInit() {}

  logoutFunc() {
    this.router.navigate(["auth/login"]);
  }
  openResetPassowrdModal(): void {
    this.modalService.open(ResetPasswordPopupComponent, {
      windowClass: "reset-password-modal-width",
      centered: true,
    });
  }
}
