import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-gallary',
  templateUrl: './car-gallary.component.html',
  styleUrl: './car-gallary.component.scss'
})
export class CarGallaryComponent implements OnInit {
  carImages: string[] = [];
  currentImage: string = '';

  ngOnInit(): void {
    this.carImages = [
      '/assets/images/seller-dashboard/car1.jfif',
      '/assets/images/seller-dashboard/car3.jfif',
      '/assets/images/seller-dashboard/car1.jfif',
      '/assets/images/seller-dashboard/car3.jfif',
      '/assets/images/seller-dashboard/car1.jfif',
      '/assets/images/seller-dashboard/car3.jfif'
    ];
    this.currentImage = '/assets/images/seller-dashboard/chevrolet-car.png'; // Default image
  }

  onImageClick(image: string): void {
    this.currentImage = image; // Update main image
  }

}
