<div class="form-group common-step-pages-dropdown">
  <label [for]="label">{{ label }}</label>
  <select
    id="{{ label }}"
    class="form-control form-select form-select-color"
    (change)="onSelectChange($event)"
    [(ngModel)]="selectedValue"
  >
    <option *ngFor="let option of options" [value]="option">
      {{ option }}
    </option>
  </select>
</div>
