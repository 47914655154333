<div class="file-upload-container">
  <!-- Dynamic Label with Upload Icon -->
  <label class="file-upload-label fw-bold d-flex align-items-center">
    {{ label }}
  </label>

  <!-- File Upload Section -->
  <div class="file-upload-content d-flex align-items-center">
    <!-- File Icon and Details -->
    <div class="file-info d-flex align-items-center">
      <img
        src="../../../../assets/images/seller-dashboard/contract.png"
        alt="File Icon"
        class="file-icon"
      />
    </div>
    <div class="w-100">
      <div
        class="file-details d-flex justify-content-between align-items-center mb-2"
      >
        <div>
          <div class="file-name">{{ fileName }}</div>
          <div class="file-size">{{ fileSize }}</div>
        </div>
        <div class="actions">
          <!-- Show Upload and Cancel while uploading -->
          <span *ngIf="!isUploadComplete" class="loading-icon">
            <!-- <i class="bi bi-arrow-repeat"></i> -->
            <img
              src="../../../../assets/images/seller-dashboard/refresh.png"
              style="width: 21px"
            />
          </span>
          <span
            *ngIf="!isUploadComplete"
            class="cancel-icon text-danger"
            (click)="cancelUpload()"
          >
            <!-- <i class="bi bi-x-circle"></i> -->
            <img
              src="../../../../assets/images/seller-dashboard/cancel-red.png"
              style="width: 21px"
            />
          </span>

          <!-- Show success checkmark on upload completion -->
          <span *ngIf="isUploadComplete" class="success-icon">
            <!-- <i class="bi bi-check-circle"></i> -->
            &#10003;
          </span>
        </div>
      </div>
      <!-- Progress Bar -->
      <div class="progress flex-grow-1">
        <div
          class="progress-bar"
          role="progressbar"
          [style.width.%]="progress"
          [ngClass]="{
            '#4ab3f4': !isUploadComplete,
            'bg-success': isUploadComplete
          }"
        ></div>
      </div>
    </div>
  </div>

  <!-- File Input (Hidden) -->
  <!-- <input
    type="file"
    class="file-input"
    [id]="uniqueId"
    accept=".pdf"
    (change)="onFileSelected($event)"
  /> -->
  <div class="custom-file-upload">
    <!-- Hidden File Input -->
    <input
      type="file"
      class="file-input"
      [id]="uniqueId"
      accept=".pdf"
      (change)="onFileSelected($event)"
    />

    <!-- Custom Button -->
    <label [for]="uniqueId" class="custom-button text-white">
      Choose File
    </label>
  </div>
</div>
