import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slider',
})
export class SliderPipe implements PipeTransform {

  transform<T>(array: T[], size: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  }
}
