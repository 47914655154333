import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-technical-specs',
  templateUrl: './product-technical-specs.component.html',
  styleUrl: './product-technical-specs.component.scss'
})
export class ProductTechnicalSpecsComponent {
  @Input() technicalSpecs: any[] = [];
}
