<div class="common-timer">
    <div class=" mt-md-3 mt-lg-0 d-flex align-items-center justify-content-center px-3 pt-md-3  pt-lg-0 timer">
        <div class="text-center rounded py-3">
            <div class="text-dark display-6">60</div>
            <div class="text-secondary">Day</div>
        </div>
        <div class="text-center rounded w-10 py-3">
            <div class=" text-secondary display-6">:</div>
            <div class="text-secondary">&nbsp;</div>
        </div>
        <div class="text-center rounded w-10 py-3">
            <div class=" text-dark display-6">30</div>
            <div class="text-secondary">Hours</div>
        </div>
        <div class="text-center rounded w-10 py-3">
            <div class=" text-secondary display-6">:</div>
            <div class="text-secondary">&nbsp;</div>
        </div>
        <div class="text-center rounded w-10 py-3">
            <div class=" text-dark font-fmy-roboto display-6">53</div>
            <div class="text-secondary">Min</div>
        </div>
    </div>
</div>