import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CommonButtonComponent } from 'src/app/shared/components/common-button/common-button.component';
import { RequestPlacedPopupComponent } from '../request-placed-popup/request-placed-popup.component'

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss',
})
export class PopupComponent {
  activeModal = inject(NgbActiveModal);
  sliderValue: any;
  constructor(private modalService: NgbModal) { }


  @Input() name!: string;
  openNewModal() {
    this.modalService.open(RequestPlacedPopupComponent, { windowClass: 'request-placed-popup', centered: true });
  }
}
