import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-latest-sale-card',
  templateUrl: './latest-sale-card.component.html',
  styleUrl: './latest-sale-card.component.scss'
})
export class LatestSaleCardComponent implements OnInit {
  screenWidth: number;
  @Input() latestSalePrice?: any;
  ngOnInit() {
    this.onResize(); // Initialize screen width
  }

  @HostListener('window:resize', [])
  onResize() {
    this.screenWidth = window.innerWidth;
  }
}
