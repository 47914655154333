<div class="row technical-specs-content">
    <div class="col-11 col-sm-6 col-md-5 col-lg-4 d-flex justify-content-between">
        <span>NUMBER OF CYLINDERS</span>
        <span>6 cylinders</span>
    </div>
    <div class="col-11 col-sm-6 col-md-5 col-lg-3 d-flex justify-content-between">
        <span>ENGINE</span>
        <span>3.0 L</span>
    </div>
    <div class="col-11 col-sm-6 col-md-5 col-lg-3 d-flex justify-content-between">
        <span>WIDTH
        </span>
        <span>10.2 IN</span>
    </div>
    <div class="col-11 col-sm-6 col-md-5 col-lg-4 d-flex justify-content-between">
        <span>FUEL TANK
        </span>
        <span>17.6 GAL </span>
    </div>
    <div class="col-11 col-sm-6 col-md-5 col-lg-3 d-flex justify-content-between">
        <span>LOCATION
        </span>
        <span>GERMANY</span>
    </div>
    <div class="col-11 col-sm-6 col-md-5 col-lg-3 d-flex justify-content-between">
        <span>LENGTH
        </span>
        <span>10.2 IN</span>
    </div>
    <div class="col-11 col-sm-6 col-md-5 col-lg-4 d-flex justify-content-between">
        <span>TOP SPEED
        </span>
        <span> 190 MPH</span>
    </div>
    <div class="col-11 col-sm-6 col-md-5 col-lg-3 d-flex justify-content-between">
        <span>MILEAGE
        </span>
        <span> 30 L</span>
    </div>
    <div class="col-11 col-sm-6 col-md-5 col-lg-3 d-flex justify-content-between">
        <span>Condition
        </span>
        <span>PERFECT</span>
    </div>
</div>