<!-- Page Sidebar Start-->
<div class="logo-wrapper">
  <a routerLink="/">
    <!-- <img src="\assets\images\layout-images\marvin-logo.png"> -->
    <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" /> -->
    <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" /> -->
  </a>
  <div class="back-btn" (click)="sidebarToggle()">
    <i class="fa fa-angle-left"></i>
  </div>
  <!-- <div class="toggle-sidebar" (click)="sidebarToggle()">
    <app-feather-icons [icon]="'grid'" class="status_toggle middle sidebar-toggle"></app-feather-icons>
  </div> -->
</div>
<div class="logo-icon-wrapper">
  <a href="javascript:void(0)">
    <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" />
  </a>
</div>
<nav class="sidebar-main">
  <div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()">
    <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
  </div>
  <div id="sidebar-menu"
    [ngStyle]="{ marginLeft: this.layout.config.settings.layout == 'Rome' || 'Singapore' || 'Barcelona' ? margin + 'px' : '0px' }">
    <ul class="sidebar-links custom-scrollbar">
      <div class="simplebar-wrapper">
        <div class="simplebar-mask">
          <div class="simplebar-offset">
            <div class="simplebar-content-wrapper">
              <div class="simplebar-content">
                <li class="back-btn">
                  <a href="javascript:void(0)">
                    <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" />
                  </a>
                  <div class="mobile-back text-end" (click)="sidebarToggle()">
                    <span>Back</span>
                    <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                  </div>
                </li>

                <li [class]="menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'"
                  *ngFor="let menuItem of menuItems" [ngClass]="{ active: menuItem.active }">
                  <div *ngIf="menuItem.headTitle1">
                    <h6 class="lan-1">{{ menuItem.headTitle1 | translate }}</h6>
                  </div>

                  <label class="badge badge-{{ menuItem.badgeType }}"
                    *ngIf="menuItem.badgeType && menuItem.badgeValue">{{ menuItem.badgeValue }}</label>
                  <!-- Sub -->
                  <a href="javascript:void(0)" class="sidebar-link bgcolornone sidebar-title"
                    [class.link-nav]="!menuItem.children" [ngClass]="{ active: menuItem.active }"
                    *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)">
                    <app-svg-icon [icon]="menuItem.icon"></app-svg-icon>
                    <span class="background-color:none">{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right"
                        *ngIf="menuItem.children"></i>
                    </div>
                  </a>
                  <!-- Link -->
                  <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active"
                    class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children"
                    [ngClass]="{ active: menuItem.active }" *ngIf="menuItem.type === 'link'">
                    <app-svg-icon [icon]="menuItem.icon"></app-svg-icon>
                    <span>{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right"
                        *ngIf="menuItem.children"></i>
                    </div>
                  </a>
                  <!-- External Link -->
                  <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-link sidebar-title"
                    [class.link-nav]="!menuItem.children" [ngClass]="{ active: menuItem.active }"
                    *ngIf="menuItem.type === 'extLink'">
                    <app-svg-icon [icon]="menuItem.icon"></app-svg-icon><span>{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i class=" fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right"
                        *ngIf="menuItem.children"></i>
                    </div>
                  </a>
                  <!-- External Tab Link -->
                  <!-- <a href="javascript:void(0)" class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children"
                    [ngClass]="{ active: menuItem.active }" *ngIf="menuItem.type === 'sub'"
                    (click)="setNavActive(menuItem)">
                    <app-svg-icon [icon]="menuItem.icon"></app-svg-icon>
                    <span>{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right"
                        *ngIf="menuItem.children"></i>
                    </div>
                  </a> -->
                  <!-- Child Menu -->
                  <ul class="sidebar-submenu" *ngIf="menuItem.children" [ngClass]="{ active: menuItem.active }"
                    [style.display]="menuItem.active ? 'block' : 'none'">
                    <li *ngFor="let child of menuItem.children" [ngClass]="{ active: child.active }">
                      <a [routerLink]="[child.path]" routerLinkActive="active" (click)="setNavActive(child)">
                        {{ child.title | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </div>
              <app-common-button label="MAKE A CAR REQUEST" bgColor="#4AB3F4" width="193px" margin="25px 0 0 36px"
                height="50px" (click)="openCarRequestModal()"></app-common-button>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </div>
  <div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
    <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
  </div>
</nav>