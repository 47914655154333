import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-common-input',
  templateUrl: './common-input.component.html',
  styleUrl: './common-input.component.scss'
})
export class CommonInputComponent {
  @Input() control!: FormControl;
  @Input() label!: string;
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() readonly: boolean = false;
  @Input() editableIcon: string = '&#9998;';
  @Input() errorMessage: string = 'Invalid input';

  @Output() editClicked = new EventEmitter<void>();

  onEditClick(): void {
    this.editClicked.emit();
  }
}
