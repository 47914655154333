import { Component, inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-request-placed-popup',

  templateUrl: './request-placed-popup.component.html',
  styleUrl: './request-placed-popup.component.scss'
})
export class RequestPlacedPopupComponent {
  activeModal = inject(NgbActiveModal);
  screenWidth: number;
  constructor(private modalService: NgbModal) { }
  closeModal(): void {
    this.modalService.dismissAll();
  }
}
