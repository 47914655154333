<div class="p-3 modal-popup-container">
    <div class="modal-header p-4">
        <div>
            <h4 class="modal-title">APPLY TO PROMOTE</h4>
            <span class="promoters-list-span"> FILL THE FORM BELOW TO APPLY</span>
        </div>
        <button type="button" class="btn-close rounded-5" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div>
        <div class="p-2 p-sm-4 fs-6 fw-normal">DESCRIBE HOW YOU WILL SELL THIS CAR</div>
        <div class="">

            <div class="p-4 promoters-list-content">
                <div class="generate-url-message p-3">Hi there :), I was checking your work on dribbble. We’re creating
                    a
                    startup related to airport
                    services and cus was checking your work on dribbble. We’re creating a startup related to airport
                    services and cust your work on dri.
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center p-3">
            <app-button label="APPLY FOR THE SELLER &nbsp;" bgColor="#4AB3F4" width="max-content"
                [iconImg]="'/assets/images/seller-dashboard/arrow-16.png'" height="45px"></app-button>
        </div>
    </div>

</div>